import { ChargingCurveEntry, CscEntry } from "../../../out/gen/openapi/routeplanner"

export interface Vehicle {
   model: string
   brand:
      | "Audi"
      | "BMW"
      | "BYD"
      | "Citroën"
      | "Ford"
      | "Hongqi"
      | "Hyundai"
      | "Volkswagen"
      | "Jaguar"
      | "Kia"
      | "MG"
      | "Renault"
      | "Mercedes"
      | "Nio"
      | "Nissan"
      | "Ora"
      | "Polestar"
      | "Porsche"
      | "Tesla"
      | "Volvo"
      | "Xpeng"
      | "Custom"

   chargingCurve: ChargingCurveEntry[]
   consumptionCurve: CscEntry[]
   capacity: number
   type: "EV" | "Diesel"
}

export const predefinedVehicles: Vehicle[] = [
   {
      model: "E-Roadster",
      brand: "Custom",
      chargingCurve: [],
      consumptionCurve: [],
      capacity: 80,
      type: "EV",
   },
   {
      model: "E-City-Car",
      brand: "Custom",
      chargingCurve: [],
      consumptionCurve: [],
      capacity: 45,
      type: "EV",
   },
   {
      model: "Car with combustion engine",
      brand: "Custom",
      chargingCurve: [],
      consumptionCurve: [],
      capacity: 0,
      type: "Diesel",
   },
   {
      model: "Audi e-tron 50",
      brand: "Audi",
      chargingCurve: [
         125, 125, 125, 125, 126, 125, 126, 124, 125, 125, 125, 124, 124, 124, 124, 124, 123, 123, 123, 123, 122, 121,
         121, 121, 120, 119, 119, 118, 117, 116, 116, 116, 115, 114, 114, 113, 112, 113, 112, 112, 113, 113, 113, 113,
         114, 114, 114, 114, 114, 114, 115, 115, 115, 115, 116, 113, 110, 107, 106, 104, 101, 99, 97, 94, 92, 89, 87,
         85, 82, 80, 78, 76, 73, 69, 66, 65, 62, 59, 57, 54, 51, 48, 45, 42, 40, 37, 34, 31, 28, 24, 22,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 20.1 },
         { sp: 120, csm: 25.6 },
      ],
      capacity: 63,
      type: "EV",
   },
   {
      model: "Audi e-tron 55 new 85.7 kWh",
      brand: "Audi",
      chargingCurve: [
         138, 138, 139, 140, 141, 141, 142, 142, 143, 142, 142, 143, 143, 143, 143, 143, 143, 143, 144, 144, 144, 144,
         144, 144, 144, 144, 144, 145, 145, 145, 145, 145, 145, 146, 146, 146, 146, 146, 146, 146, 146, 147, 147, 147,
         147, 147, 148, 148, 148, 149, 149, 149, 150, 150, 150, 150, 151, 151, 148, 144, 141, 137, 134, 132, 127, 123,
         121, 118, 114, 110, 107, 103, 100, 96, 93, 89, 85, 81, 77, 74, 70, 66, 62, 58, 54, 50, 46, 42, 38, 34, 28,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 22.5 },
         { sp: 120, csm: 30.8 },
      ],
      capacity: 83.1,
      type: "EV",
   },
   {
      model: "Audi e-tron GT 93 kWh May 2021",
      brand: "Audi",
      chargingCurve: [
         243, 243, 251, 253, 253, 254, 255, 256, 256, 256, 258, 259, 258, 260, 260, 261, 261, 262, 262, 262, 253, 254,
         243, 244, 244, 244, 244, 245, 245, 245, 245, 246, 246, 246, 246, 246, 247, 247, 247, 248, 249, 228, 229, 228,
         208, 208, 209, 209, 190, 191, 190, 180, 180, 181, 166, 172, 172, 173, 161, 163, 163, 153, 154, 154, 120, 121,
         122, 122, 122, 123, 90, 84, 73, 62, 53, 44, 38, 36, 33, 43, 54, 66, 45, 35, 33, 25, 17, 11,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 17.3 },
         { sp: 120, csm: 22.4 },
      ],
      capacity: 84.6,
      type: "EV",
   },
   {
      model: "BMW i4 February 2021",
      brand: "BMW",
      chargingCurve: [
         200, 201, 201, 202, 203, 205, 205, 206, 208, 197, 197, 181, 181, 182, 182, 183, 174, 175, 175, 168, 168, 168,
         158, 162, 162, 163, 158, 158, 158, 144, 144, 144, 144, 144, 144, 125, 125, 126, 126, 126, 126, 115, 115, 115,
         116, 105, 105, 105, 100, 102, 102, 96, 97, 94, 93, 91, 92, 89, 90, 88, 87, 87, 84, 84, 84, 79, 77, 70, 70, 71,
         67, 59, 59, 59, 59, 59, 59, 59, 52, 52, 52,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 15.5 },
         { sp: 120, csm: 21.7 },
      ],
      capacity: 88.3,
      type: "EV",
   },
   {
      model: "BMW i4 M50 overheating",
      brand: "BMW",
      chargingCurve: [
         204, 205, 205, 206, 206, 207, 207, 207, 208, 208, 208, 209, 209, 210, 210, 189, 168, 172, 171, 160, 153, 148,
         143, 135, 128, 123, 116, 110, 104, 98, 91, 86, 80, 75, 70, 64, 60, 53, 47, 44, 42, 42, 43, 44, 50, 54, 57, 59,
         59, 57, 54, 53, 52, 52, 52, 52, 56, 59, 63, 67, 69, 70, 70, 71, 71, 73, 74, 70, 70, 70, 64, 64, 64, 64, 64, 64,
         62, 59, 58, 57, 58,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 15.5 },
         { sp: 120, csm: 21.7 },
      ],
      capacity: 80.3,
      type: "EV",
   },
   {
      model: "BMW iX3 April 2021",
      brand: "BMW",
      chargingCurve: [
         153, 154, 155, 155, 155, 155, 156, 147, 149, 149, 149, 149, 149, 149, 150, 150, 150, 146, 148, 147, 147, 148,
         148, 148, 148, 149, 149, 140, 142, 142, 105, 105, 105, 105, 105, 105, 105, 121, 121, 122, 122, 122, 122, 123,
         107, 108, 108, 109, 109, 109, 109, 109, 92, 93, 94, 94, 94, 95, 95, 95, 79, 79, 80, 80, 80, 60, 60, 60, 46, 46,
         47, 47, 47, 47, 47, 45, 46, 46, 46, 46, 46,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 16.8 },
         { sp: 120, csm: 23.5 },
      ],
      capacity: 74.2,
      type: "EV",
   },
   {
      model: "BMW iX40 May 2022",
      brand: "BMW",
      chargingCurve: [
         144, 145, 145, 145, 145, 149, 148, 148, 149, 148, 138, 139, 138, 139, 140, 139, 140, 140, 140, 140, 140, 119,
         119, 119, 120, 120, 120, 120, 121, 121, 121, 121, 121, 93, 93, 93, 93, 93, 94, 94, 94, 94, 94, 73, 71, 72, 72,
         72, 72, 72, 72, 73, 73, 73, 73, 73, 73, 73, 73, 74, 74, 74, 74, 74, 76, 76, 76, 58, 45, 45, 43, 43, 43, 44, 43,
         43, 44, 44, 44, 44, 44,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 50, csm: 13.8 }, // https://ev-database.org/de/pkw/1472/BMW-iX-xDrive-40
         { sp: 110, csm: 21.5 }, // https://ev-database.org/de/pkw/1472/BMW-iX-xDrive-40
      ],
      capacity: 71,
      type: "EV", // https://ev-database.org/de/pkw/1472/BMW-iX-xDrive-40
   },
   {
      model: "BMW iX50 Feb 2022",
      brand: "BMW",
      chargingCurve: [
         186, 186, 187, 188, 189, 189, 190, 190, 190, 191, 191, 192, 192, 193, 193, 193, 194, 194, 194, 194, 195, 195,
         195, 196, 196, 197, 197, 197, 197, 198, 198, 188, 188, 165, 164, 158, 156, 157, 157, 148, 149, 149, 143, 143,
         134, 135, 135, 128, 129, 130, 130, 131, 126, 127, 126, 122, 122, 118, 117, 114, 113, 111, 109, 109, 107, 105,
         100, 93, 93, 79, 88, 80, 80, 80, 80, 80, 80, 65, 69, 68, 69, 69, 69, 69, 68, 59, 63, 63, 64, 36, 5,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 50, csm: 14.6 },
         { sp: 110, csm: 22.6 },
      ],
      capacity: 105.2,
      type: "EV",
   },
   {
      model: "BYD Atto 3 60 kWh",
      brand: "BYD",
      chargingCurve: [
         86, 86, 86, 87, 87, 87, 87, 87, 87, 87, 87, 88, 88, 88, 88, 88, 88, 88, 88, 88, 80, 52, 51, 51, 51, 51, 51, 51,
         51, 51, 51, 51, 51, 51, 51, 51, 51, 82, 88, 68, 58, 58, 58, 58, 58, 58, 58, 58, 58, 58, 58, 58, 58, 58, 58, 58,
         58, 58, 58, 58, 59, 58, 53, 51, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 33, 33, 33, 33, 33, 33, 33, 33, 33,
         33, 33, 33, 33, 33, 17, 17,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [{ sp: 90, csm: 13.4 }],
      capacity: 59.6,
      type: "EV",
   },
   {
      model: "BYD Tang 86.4 kWh August 2022",
      brand: "BYD",
      chargingCurve: [
         115, 116, 116, 116, 116, 116, 117, 117, 117, 117, 117, 117, 117, 117, 118, 118, 118, 118, 118, 118, 118, 118,
         118, 118, 118, 118, 118, 118, 118, 118, 118, 118, 118, 118, 118, 119, 119, 119, 119, 119, 119, 119, 119, 119,
         119, 119, 119, 119, 119, 119, 71, 71, 73, 73, 84, 84, 86, 86, 86, 86, 86, 86, 86, 86, 86, 86, 86, 86, 86, 86,
         86, 86, 86, 86, 47, 48, 48, 49, 49, 49, 49, 49, 49, 50, 50, 50, 50, 36, 23, 18, 13,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 21.6 },
         { sp: 120, csm: 31.7 },
      ],
      capacity: 83.4,
      type: "EV",
   },
   {
      model: "Citroen e-C4 50 kWh",
      brand: "Citroën",
      chargingCurve: [
         99, 99, 100, 100, 100, 100, 100, 99, 99, 97, 96, 96, 96, 97, 97, 97, 98, 98, 98, 98, 98, 99, 78, 78, 78, 78,
         78, 78, 79, 79, 79, 79, 79, 79, 79, 79, 80, 80, 80, 80, 80, 80, 80, 80, 80, 81, 81, 65, 64, 64, 64, 64, 64, 65,
         65, 65, 55, 56, 55, 56, 56, 56, 56, 56, 56, 56, 56, 57, 57, 57, 29, 29, 29, 30, 30, 30, 30, 30, 30, 30, 30,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 13.7 },
         { sp: 120, csm: 20.7 },
      ],
      capacity: 40.9,
      type: "EV",
   },
   {
      model: "Ford Mustang Mach-e Febuary 2021",
      brand: "Ford",
      chargingCurve: [
         130, 131, 132, 133, 136, 139, 143, 118, 111, 111, 112, 112, 111, 111, 112, 112, 112, 112, 112, 112, 112, 113,
         112, 107, 105, 103, 103, 103, 102, 97, 96, 95, 96, 96, 96, 90, 85, 81, 79, 78, 77, 77, 77, 77, 76, 77, 77, 77,
         78, 78, 77, 78, 78, 78, 78, 78, 79, 79, 77, 77, 77, 77, 78, 78, 78, 78, 78, 79, 79, 77, 12, 11, 11, 11, 11, 12,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 19.8 },
         { sp: 120, csm: 27.1 },
      ],
      capacity: 85.6,
      type: "EV",
   },
   {
      model: "Ford Mustang Mach-e GT July 2022",
      brand: "Ford",
      chargingCurve: [
         148, 148, 149, 150, 144, 109, 105, 105, 105, 105, 105, 105, 105, 106, 106, 106, 106, 106, 106, 105, 103, 102,
         102, 99, 95, 94, 93, 93, 93, 93, 93, 89, 83, 79, 77, 77, 77, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76,
         76, 76, 77, 77, 77, 77, 77, 77, 77, 77, 78, 78, 78, 77, 76, 74, 72, 70, 69, 66, 64, 35, 35, 36, 36, 37, 37, 37,
         38, 39, 39, 39, 40, 40, 40, 32, 32, 26, 20,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 19.8 },
         { sp: 120, csm: 27.1 },
      ],
      capacity: 85.6,
      type: "EV",
   },
   {
      model: "Hongqi E-HS9 99 kWh",
      brand: "Hongqi",
      chargingCurve: [
         124, 124, 125, 125, 126, 125, 124, 123, 122, 120, 119, 120, 120, 120, 120, 120, 121, 121, 122, 122, 122, 122,
         122, 123, 123, 123, 123, 123, 123, 124, 124, 124, 125, 125, 125, 125, 125, 125, 124, 121, 118, 116, 113, 110,
         107, 105, 106, 106, 107, 107, 107, 105, 105, 104, 103, 103, 102, 102, 101, 100, 99, 99, 98, 98, 97, 96, 95, 94,
         91, 87, 80, 77, 71, 67, 62, 57, 53, 49, 44, 41, 39,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 21.8 },
         { sp: 120, csm: 32.1 },
      ],
      capacity: 86.5,
      type: "EV",
   },
   {
      model: "Hyundai Ioniq 28 kWh",
      brand: "Hyundai",
      chargingCurve: [
         62, 62, 62, 62, 62, 62, 62, 62, 62, 62, 62, 63, 63, 63, 63, 64, 64, 64, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65,
         65, 65, 65, 65, 65, 65, 65, 65, 65, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 67, 67, 67, 67, 67, 67,
         68, 68, 68, 68, 68, 68, 69, 69, 69, 69, 69, 69, 60, 57, 53, 50, 47, 22, 22, 22, 22, 22, 23, 23, 23,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 15.6 },
         { sp: 120, csm: 20.6 },
      ],
      capacity: 28,
      type: "EV",
   },
   {
      model: "Jaguar I-Pace",
      brand: "Jaguar",
      chargingCurve: [
         94, 98, 94, 94, 94, 94, 95, 94, 94, 104, 104, 104, 104, 104, 104, 104, 104, 104, 104, 104, 104, 104, 104, 104,
         104, 104, 103, 101, 101, 96, 92, 92, 92, 83, 83, 83, 84, 74, 74, 74, 74, 74, 75, 71, 72, 72, 73, 73, 66, 66,
         64, 65, 65, 57, 57, 57, 57, 58, 64, 57, 58, 49, 49, 49, 49, 49, 49, 49, 49, 40, 40, 40, 40, 40, 40, 40, 39, 40,
         40, 30, 30,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 19.6 },
         { sp: 120, csm: 26.9 },
      ],
      capacity: 81.8,
      type: "EV",
   },
   {
      model: "Kia Niro EV 64 kWh October 2022",
      brand: "Kia",
      chargingCurve: [
         81, 82, 82, 79, 79, 79, 82, 83, 83, 83, 83, 83, 83, 83, 83, 83, 83, 83, 83, 84, 84, 77, 77, 77, 77, 77, 78, 78,
         78, 78, 78, 78, 78, 78, 78, 63, 63, 64, 64, 64, 64, 64, 64, 64, 65, 65, 65, 65, 58, 58, 58, 58, 58, 59, 59, 59,
         59, 59, 59, 60, 47, 44, 44, 44, 44, 44, 44, 45, 45, 45, 44, 44, 44, 25, 25, 25, 25, 25, 25, 25, 25,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 15.4 },
         { sp: 120, csm: 22.3 },
      ],
      capacity: 64.3,
      type: "EV",
   },
   {
      model: "Kia e-Soul 64 kWh",
      brand: "Kia",
      chargingCurve: [
         67, 67, 67, 67, 68, 68, 68, 68, 68, 68, 69, 69, 69, 69, 69, 69, 69, 69, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70,
         70, 70, 71, 71, 71, 71, 71, 71, 71, 71, 71, 71, 72, 72, 72, 72, 65, 66, 53, 54, 54, 54, 54, 54, 55, 55, 55, 55,
         55, 55, 55, 55, 56, 56, 56, 56, 35, 34, 34, 34, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 24, 24, 24,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 50, csm: 11.6 }, //https://ev-database.org/de/pkw/1749/Kia-e-Soul-64-kWh#efficiency
         { sp: 110, csm: 19.1 }, //https://ev-database.org/de/pkw/1749/Kia-e-Soul-64-kWh#efficiency
      ],
      capacity: 64,
      type: "EV", //https://ev-database.org/de/pkw/1749/Kia-e-Soul-64-kWh#efficiency
   },
   {
      model: "MG Marvel R 70 kWh September 2022",
      brand: "MG",
      chargingCurve: [
         77, 78, 79, 79, 80, 80, 81, 81, 81, 81, 81, 81, 81, 81, 81, 82, 82, 82, 82, 82, 82, 82, 82, 82, 82, 82, 82, 83,
         81, 81, 81, 81, 81, 81, 81, 81, 82, 82, 71, 70, 70, 70, 70, 70, 71, 71, 71, 71, 71, 72, 72, 63, 63, 63, 63, 63,
         63, 64, 64, 64, 64, 64, 65, 55, 55, 55, 55, 55, 55, 55, 55, 25, 25, 25, 25, 25, 24, 24, 24, 18, 16,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 18.2 },
         { sp: 120, csm: 28.4 },
      ],
      capacity: 69.7,
      type: "EV",
   },
   {
      model: "MG ZS EV 72 kWh",
      brand: "MG",
      chargingCurve: [
         84, 84, 85, 86, 86, 87, 87, 87, 87, 87, 87, 87, 87, 87, 87, 87, 87, 88, 88, 88, 88, 89, 89, 89, 89, 89, 89, 89,
         89, 89, 88, 87, 87, 87, 88, 88, 88, 88, 88, 88, 83, 77, 76, 76, 76, 76, 76, 76, 77, 77, 77, 77, 75, 67, 67, 67,
         67, 67, 68, 68, 68, 68, 68, 66, 58, 58, 58, 58, 58, 59, 59, 59, 59, 28, 23, 22, 22, 22, 22, 18, 14,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 19.0 },
         { sp: 120, csm: 26.0 },
      ],
      capacity: 67.6,
      type: "EV",
   },
   {
      model: "MG4 51 kWh",
      brand: "MG",
      chargingCurve: [
         81, 81, 84, 84, 84, 85, 85, 85, 85, 85, 85, 86, 86, 86, 86, 86, 86, 86, 85, 84, 84, 83, 82, 81, 80, 79, 78, 77,
         76, 76, 75, 74, 74, 73, 73, 72, 72, 71, 71, 71, 70, 69, 69, 68, 68, 67, 67, 66, 66, 65, 63, 61, 58, 56, 54, 51,
         49, 47, 45, 43, 42, 42, 41, 41, 41, 41, 41, 40, 40, 36, 27, 26, 25, 25, 25, 24, 22, 21, 19, 17, 15,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 15.0 },
         { sp: 120, csm: 21.9 },
      ],
      capacity: 49.5,
      type: "EV",
   },
   {
      model: "MG5 61 kWh",
      brand: "MG",
      chargingCurve: [
         84, 86, 88, 89, 90, 90, 90, 90, 90, 90, 90, 90, 90, 89, 89, 89, 89, 90, 90, 91, 92, 92, 92, 92, 92, 93, 93, 93,
         93, 93, 93, 93, 92, 91, 89, 88, 87, 86, 85, 83, 82, 81, 80, 79, 78, 77, 76, 74, 70, 69, 69, 69, 69, 69, 68, 67,
         66, 65, 63, 62, 61, 60, 59, 58, 58, 57, 56, 55, 55, 54, 51, 51, 51, 42, 36, 36, 36, 36, 32, 26, 21,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 17.3 },
         { sp: 120, csm: 24.7 },
      ],
      capacity: 55.5,
      type: "EV",
   },
   {
      model: "Megane 60 kWh",
      brand: "Renault",
      chargingCurve: [
         126, 126, 127, 127, 127, 125, 122, 121, 116, 115, 113, 113, 111, 110, 109, 108, 106, 106, 104, 102, 101, 99,
         95, 95, 94, 92, 91, 86, 86, 84, 84, 79, 79, 75, 72, 70, 69, 69, 67, 67, 66, 65, 65, 64, 63, 62, 61, 61, 62, 61,
         62, 61, 59, 57, 56, 55, 55, 54, 53, 53, 52, 50, 48, 47, 47, 47, 46, 45, 45, 42, 42, 40, 40, 40, 39, 37, 37, 35,
         28, 28, 28,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 14.7 },
         { sp: 120, csm: 19.9 },
      ],
      capacity: 54.5,
      type: "EV",
   },
   {
      model: "Mercedes EQA 70 kWh",
      brand: "Mercedes",
      chargingCurve: [
         109, 109, 109, 110, 110, 110, 110, 111, 111, 111, 111, 112, 112, 112, 112, 112, 112, 112, 111, 111, 110, 110,
         110, 109, 109, 109, 108, 108, 108, 108, 107, 107, 107, 106, 106, 106, 106, 105, 104, 103, 103, 102, 102, 101,
         100, 100, 99, 98, 98, 97, 97, 96, 95, 94, 94, 93, 92, 92, 91, 89, 88, 86, 85, 83, 82, 80, 78, 77, 75, 74, 73,
         72, 71, 71, 70, 69, 69, 68, 67, 66, 61, 58, 54, 52, 48, 45, 42, 39, 35,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 50, csm: 13 }, //https://ev-database.org/de/pkw/1496/Mercedes-EQA-350-4MATIC
         { sp: 110, csm: 20.8 }, //https://ev-database.org/de/pkw/1496/Mercedes-EQA-350-4MATIC
      ],
      capacity: 66.5,
      type: "EV", //https://ev-database.org/de/pkw/1496/Mercedes-EQA-350-4MATIC
   },
   {
      model: "Mercedes EQC",
      brand: "Mercedes",
      chargingCurve: [
         104, 105, 105, 106, 106, 106, 107, 107, 107, 107, 107, 107, 107, 107, 107, 107, 108, 108, 108, 108, 108, 108,
         109, 109, 109, 109, 109, 109, 109, 109, 108, 107, 106, 105, 104, 103, 102, 101, 100, 99, 98, 97, 96, 95, 94,
         93, 92, 92, 91, 90, 90, 90, 89, 88, 88, 88, 87, 86, 85, 84, 83, 82, 79, 77, 75, 74, 72, 71, 71, 70, 69, 68, 67,
         66, 65, 64, 62, 59, 57, 54, 53,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 19.2 },
         { sp: 120, csm: 27.0 },
      ],
      capacity: 80,
      type: "EV",
   },
   {
      model: "Mercedes EQE June 2022",
      brand: "Mercedes",
      chargingCurve: [
         163, 164, 165, 166, 167, 168, 169, 169, 170, 170, 170, 170, 170, 170, 170, 170, 171, 172, 172, 172, 172, 172,
         172, 172, 171, 171, 170, 168, 168, 168, 167, 166, 165, 164, 163, 162, 161, 160, 159, 158, 157, 156, 153, 150,
         147, 144, 143, 142, 141, 141, 140, 137, 136, 134, 132, 130, 128, 126, 123, 119, 112, 108, 106, 103, 99, 96, 93,
         90, 87, 84, 81, 77, 74, 71, 68, 64, 60, 57, 54, 50, 47,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 50, csm: 13.8 }, //https://ev-database.org/de/pkw/1901/Mercedes-EQE-AMG-43-4MATIC
         { sp: 110, csm: 20.8 }, //https://ev-database.org/de/pkw/1901/Mercedes-EQE-AMG-43-4MATIC
      ],
      capacity: 90.6,
      type: "EV", //https://ev-database.org/de/pkw/1901/Mercedes-EQE-AMG-43-4MATIC
   },
   {
      model: "Mercedes EQS 450+ eco charge",
      brand: "Mercedes",
      chargingCurve: [
         101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
         101, 101, 101, 101, 102, 102, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 102, 102, 102,
         102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102,
         102, 102, 102, 102, 102, 100, 98, 95, 89, 85, 80, 75, 70, 65, 61,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 15.0 },
         { sp: 120, csm: 19.8 },
      ],
      capacity: 106.7,
      type: "EV",
   },
   {
      model: "Mercedes EQS November 2021",
      brand: "Mercedes",
      chargingCurve: [
         198, 198, 200, 200, 201, 202, 203, 203, 203, 204, 204, 204, 205, 205, 205, 206, 207, 207, 207, 207, 205, 200,
         196, 193, 187, 186, 184, 183, 181, 180, 178, 177, 175, 174, 172, 171, 169, 167, 165, 164, 162, 160, 158, 157,
         155, 153, 150, 149, 148, 146, 144, 142, 140, 138, 136, 135, 132, 130, 129, 127, 126, 124, 123, 120, 119, 118,
         117, 115, 114, 110, 105, 100, 96, 91, 87, 82, 76, 71, 66, 62, 57,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 15 },
         { sp: 120, csm: 19.8 },
      ],
      capacity: 106.7,
      type: "EV",
   },
   {
      model: "Nio ES8 October 2021",
      brand: "Nio",
      chargingCurve: [
         103, 105, 106, 106, 106, 106, 108, 108, 108, 108, 108, 108, 109, 109, 109, 109, 109, 109, 109, 90, 90, 90, 90,
         90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 93, 92,
         91, 90, 89, 89, 88, 87, 86, 86, 85, 84, 84, 83, 82, 81, 79, 78, 77, 75, 74, 73, 71, 70, 68, 67, 66, 64, 63, 62,
         59, 56, 50, 47,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 22.1 },
         { sp: 120, csm: 30.0 },
      ],
      capacity: 87.9,
      type: "EV",
   },
   {
      model: "Nissan Ariya 63 kWh",
      brand: "Nissan",
      chargingCurve: [
         116, 116, 116, 115, 115, 115, 115, 115, 115, 115, 115, 115, 116, 116, 116, 116, 116, 112, 111, 109, 108, 108,
         107, 106, 105, 104, 103, 102, 102, 101, 100, 99, 98, 97, 96, 96, 95, 94, 93, 92, 92, 91, 90, 89, 88, 87, 86,
         85, 83, 83, 82, 80, 80, 78, 77, 76, 75, 74, 73, 72, 71, 70, 69, 68, 67, 66, 65, 64, 63, 62, 61, 60, 59, 58, 57,
         55, 54, 53, 52, 51, 49, 48, 47, 45, 43, 42, 41, 40, 38, 37, 15,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 17.1 },
         { sp: 120, csm: 25.2 },
      ],
      capacity: 58.5,
      type: "EV",
   },
   {
      model: "Nissan Ariya 63 kWh 90 kW curve",
      brand: "Nissan",
      chargingCurve: [
         115, 115, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90,
         90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90,
         90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 17.1 },
         { sp: 120, csm: 25.2 },
      ],
      capacity: 58.5,
      type: "EV",
   },
   {
      model: "Nissan Ariya 87 kWh",
      brand: "Nissan",
      chargingCurve: [
         115, 115, 115, 116, 115, 115, 115, 115, 115, 115, 115, 115, 115, 115, 115, 115, 116, 115, 115, 115, 115, 115,
         115, 115, 115, 115, 115, 115, 115, 115, 115, 115, 115, 115, 115, 115, 115, 115, 115, 115, 115, 115, 114, 114,
         111, 110, 109, 108, 107, 106, 104, 102, 101, 100, 99, 97, 96, 95, 94, 93, 91, 90, 89, 89, 88, 87, 86, 85, 84,
         83, 81, 80, 79, 77, 76, 74, 73, 71, 70, 67, 66, 64, 62, 59, 57, 55, 52, 40, 40, 23,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 19.9 },
         { sp: 120, csm: 27.2 },
      ],
      capacity: 79.9,
      type: "EV",
   },
   {
      model: "Ora Good Cat GT 63 kWh",
      brand: "Ora",
      chargingCurve: [
         48, 48, 47, 47, 47, 47, 47, 46, 46, 46, 46, 46, 46, 46, 46, 46, 46, 46, 46, 46, 46, 46, 46, 46, 57, 57, 57, 57,
         57, 57, 57, 57, 57, 57, 57, 51, 52, 52, 52, 52, 52, 52, 53, 53, 53, 53, 47, 47, 44, 44, 44, 44, 44, 44, 44, 45,
         45, 37, 37, 37, 35, 35, 35, 36, 36, 36, 36, 36, 36, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [{ sp: 90, csm: 14.8 }],
      capacity: 56.8,
      type: "EV",
   },
   {
      model: "Polestar 2 64 kWh",
      brand: "Polestar",
      chargingCurve: [
         110, 110, 112, 113, 113, 113, 114, 114, 114, 114, 114, 114, 115, 115, 115, 115, 115, 115, 115, 115, 115, 116,
         116, 116, 116, 116, 116, 117, 117, 117, 117, 93, 93, 94, 94, 94, 94, 94, 95, 95, 95, 95, 95, 67, 69, 69, 69,
         69, 69, 69, 69, 70, 70, 60, 60, 61, 61, 61, 61, 55, 56, 57, 57, 57, 57, 47, 47, 48, 48, 48, 48, 48, 48, 26, 27,
         27, 27, 27, 27, 27, 11,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: -1 },
         { sp: 120, csm: -1 },
      ],
      capacity: 64.0,
      type: "EV",
   },
   {
      model: "Polestar 2 78 kWh",
      brand: "Polestar",
      chargingCurve: [
         130, 132, 133, 137, 140, 143, 146, 148, 149, 144, 146, 147, 147, 144, 145, 139, 141, 141, 141, 134, 136, 136,
         136, 125, 126, 128, 129, 117, 116, 118, 118, 113, 115, 110, 111, 106, 102, 100, 101, 99, 100, 95, 96, 90, 92,
         89, 86, 87, 85, 80, 78, 75, 76, 74, 71, 71, 64, 65, 64, 63, 62, 60, 58, 55, 53, 50, 49, 48, 46, 45, 43, 40, 39,
         37, 37, 36, 32, 31, 29, 29, 14,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 16.7 },
         { sp: 120, csm: 22.9 },
      ],
      capacity: 72.8,
      type: "EV",
   },
   {
      model: "Polestar 2 refresh 82 kWh",
      brand: "Polestar",
      chargingCurve: [
         205, 206, 206, 207, 207, 204, 205, 192, 192, 192, 191, 191, 191, 173, 175, 175, 175, 175, 163, 166, 167, 168,
         168, 162, 163, 163, 158, 159, 150, 151, 152, 152, 144, 146, 146, 138, 141, 141, 130, 129, 127, 118, 121, 121,
         117, 118, 116, 117, 112, 114, 111, 112, 109, 111, 109, 107, 103, 105, 105, 102, 98, 99, 94, 95, 84, 86, 60, 61,
         61, 44, 48, 45, 46, 43, 39, 40, 39, 38, 38, 35, 36,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 15.3 },
         { sp: 120, csm: 21.8 },
      ],
      capacity: 79.1,
      type: "EV",
   },
   {
      model: "Porsche Taycan 93 kWh",
      brand: "Porsche",
      chargingCurve: [
         250, 252, 252, 253, 254, 254, 255, 256, 256, 257, 259, 259, 260, 260, 260, 261, 261, 252, 252, 252, 200, 200,
         200, 200, 200, 200, 201, 150, 151, 150, 150, 151, 151, 151, 151, 152, 152, 152, 152, 153, 153, 153, 153, 153,
         154, 154, 155, 155, 155, 155, 155, 156, 157, 157, 157, 157, 157, 157, 158, 159, 159, 150, 150, 118, 118, 119,
         118, 119, 118, 84, 83, 77, 66, 55, 47, 39, 34, 32, 42, 50, 51,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 15.0 },
         { sp: 120, csm: 20.3 },
      ],
      capacity: 86.9,
      type: "EV",
   },
   {
      model: "Porsche Taycan 93 kWh June 2022 eco",
      brand: "Porsche",
      chargingCurve: [
         190, 193, 194, 194, 193, 193, 194, 192, 191, 192, 190, 189, 188, 188, 186, 186, 185, 183, 183, 184, 179, 177,
         172, 169, 164, 161, 158, 155, 152, 151, 148, 145, 144, 142, 141, 139, 138, 136, 135, 134, 133, 131, 131, 130,
         129, 128, 127, 125, 123, 122, 122, 122, 120, 119, 118, 115, 113, 111, 110, 108, 106, 106, 105, 104, 103, 102,
         100, 97, 95, 95, 93, 86, 83, 82, 76, 76, 79, 78, 84, 89, 79, 70, 50, 53, 38, 25, 30, 19,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 15.0 },
         { sp: 120, csm: 20.3 },
      ],
      capacity: 86.9,
      type: "EV",
   },
   {
      model: "Tesla Model 3 60 kWh May 2022",
      brand: "Tesla",
      chargingCurve: [
         170, 170, 169, 169, 166, 158, 152, 146, 143, 139, 137, 134, 132, 130, 129, 127, 126, 125, 125, 124, 123, 122,
         121, 119, 119, 118, 116, 115, 113, 112, 110, 109, 107, 106, 105, 103, 102, 100, 98, 97, 96, 94, 93, 91, 89, 87,
         86, 84, 82, 80, 78, 76, 75, 73, 71, 69, 67, 66, 64, 62, 60, 59, 57, 55, 53, 51, 50, 48, 46, 45, 44, 42, 40, 39,
         38, 37, 36, 34, 33, 32, 30, 28, 27, 26, 24, 23, 21, 17, 14, 9, 8,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: -1 },
         { sp: 120, csm: -1 },
      ],
      capacity: 57.5,
      type: "EV",
   },
   {
      model: "Tesla Model 3 LR 80 kWh Panasonic",
      brand: "Tesla",
      chargingCurve: [
         250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 249, 249, 245, 241, 235, 232, 227,
         222, 218, 214, 210, 205, 202, 198, 195, 189, 182, 173, 167, 162, 155, 151, 145, 141, 136, 132, 129, 126, 125,
         122, 122, 120, 119, 118, 116, 115, 114, 113, 110, 106, 103, 100, 98, 95, 92, 90, 87, 84, 81, 78, 74, 71, 67,
         64, 60, 57, 55, 52, 48, 44, 41, 38, 36, 36, 33, 32,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: -1 },
         { sp: 120, csm: -1 },
      ],
      capacity: 82.0,
      type: "EV",
   },
   {
      model: "Tesla Model 3 Performance May 2022",
      brand: "Tesla",
      chargingCurve: [
         250, 250, 250, 250, 250, 250, 247, 244, 238, 235, 232, 228, 224, 218, 213, 209, 190, 175, 167, 165, 164, 165,
         164, 165, 165, 165, 155, 150, 146, 142, 137, 132, 128, 124, 120, 114, 111, 107, 103, 100, 96, 93, 90, 87, 85,
         83, 82, 81, 80, 79, 78, 78, 77, 75, 74, 72, 70, 69, 67, 65, 63, 62, 60, 59, 58, 55, 55, 53, 51, 49, 46, 45, 43,
         42, 40, 39, 37, 36, 35, 34, 33,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: -1 },
         { sp: 120, csm: -1 },
      ],
      capacity: 82.0,
      type: "EV",
   },
   {
      model: "Tesla Model 3 SR+ MIC Aug 2021",
      brand: "Tesla",
      chargingCurve: [
         166, 166, 166, 165, 162, 159, 156, 153, 152, 150, 149, 147, 145, 145, 143, 141, 139, 137, 136, 133, 131, 129,
         126, 124, 121, 119, 116, 114, 112, 109, 107, 103, 101, 99, 97, 96, 93, 91, 90, 88, 87, 86, 84, 82, 81, 78, 77,
         75, 73, 71, 70, 68, 66, 64, 63, 61, 61, 59, 58, 57, 56, 55, 54, 53, 52, 50, 49, 48, 47, 47, 45, 44, 44, 42, 41,
         40, 39, 38, 37, 35, 35,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 14.2 },
         { sp: 120, csm: 19.9 },
      ],
      capacity: 57.5,
      type: "EV",
   },
   {
      model: "Tesla Model S LR Palladium Feb 2023",
      brand: "Tesla",
      chargingCurve: [
         156, 200, 248, 247, 246, 245, 244, 244, 244, 245, 246, 250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 249,
         245, 236, 229, 219, 213, 209, 203, 197, 191, 183, 178, 172, 168, 162, 158, 154, 151, 146, 143, 140, 136, 133,
         130, 127, 124, 121, 119, 115, 114, 111, 108, 106, 104, 101, 99, 96, 94, 93, 90, 88, 85, 83, 80, 78, 76, 72, 70,
         68, 66, 63, 62, 60, 58, 56, 55, 54, 52, 50, 49, 45, 40, 36, 32, 28, 25,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 15.7 },
         { sp: 120, csm: 21.6 },
      ],
      capacity: 92.1,
      type: "EV",
   },
   {
      model: "Tesla Model S P85 2013",
      brand: "Tesla",
      chargingCurve: [
         110, 105, 100, 96, 92, 90, 87, 86, 83, 81, 80, 79, 77, 75, 74, 72, 71, 71, 70, 69, 68, 68, 67, 66, 65, 63, 63,
         63, 62, 61, 60, 58, 58, 57, 56, 55, 54, 53, 52, 51, 50, 50, 49, 48, 47, 47, 46, 45, 44, 43, 43, 43, 42, 41, 41,
         40, 40, 39, 39, 39, 38, 38, 37, 37, 36, 35, 35, 34, 33, 32, 30, 29, 26, 25, 25, 23, 22, 21, 19, 17, 14,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 17.2 },
         { sp: 120, csm: 23.9 },
      ],
      capacity: 64.9,
      type: "EV",
   },
   {
      model: "Tesla Model S Plaid Feb 2023",
      brand: "Tesla",
      chargingCurve: [
         246, 246, 246, 246, 246, 246, 246, 246, 246, 246, 246, 246, 245, 244, 244, 238, 234, 227, 222, 217, 212, 206,
         201, 196, 191, 187, 180, 177, 173, 168, 163, 159, 156, 152, 148, 144, 140, 136, 134, 130, 127, 124, 121, 119,
         116, 114, 111, 109, 106, 104, 102, 100, 98, 96, 94, 92, 90, 88, 86, 82, 80, 78, 75, 73, 70, 67, 63, 61, 59, 56,
         54, 53, 52, 50, 49, 46, 43, 40, 37, 35, 32,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 17.0 },
         { sp: 120, csm: 23.5 },
      ],
      capacity: 92.1,
      type: "EV",
   },
   {
      model: "Tesla Model S Plaid cold Feb 2023",
      brand: "Tesla",
      chargingCurve: [
         149, 149, 149, 154, 160, 161, 162, 162, 159, 159, 158, 157, 155, 155, 155, 154, 153, 151, 151, 150, 148, 148,
         145, 145, 144, 142, 140, 139, 138, 137, 136, 135, 133, 132, 131, 130, 129, 128, 127, 126, 125, 124, 122, 118,
         117, 116, 114, 113, 110, 109, 106, 104, 102, 100, 98, 96, 94, 92, 90, 88, 84, 82, 80, 77, 74, 71, 69, 67, 63,
         61, 59, 57, 55, 54, 52, 51, 50, 47, 45, 41, 40,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 17.0 },
         { sp: 120, csm: 23.5 },
      ],
      capacity: 92.1,
      type: "EV",
   },
   {
      model: "Tesla Model Y LR LG April 2022",
      brand: "Tesla",
      chargingCurve: [
         250, 246, 233, 220, 211, 200, 197, 193, 190, 188, 183, 179, 175, 172, 171, 167, 165, 162, 159, 157, 154, 151,
         148, 146, 143, 139, 138, 135, 132, 130, 128, 125, 123, 122, 118, 117, 114, 112, 110, 108, 105, 103, 101, 99,
         97, 94, 93, 91, 89, 85, 84, 81, 79, 79, 77, 76, 75, 74, 73, 72, 71, 70, 69, 67, 66, 65, 64, 62, 61, 61, 60, 58,
         56, 53, 50, 48, 45, 43, 42, 41, 39, 38, 36, 35, 32, 30, 27, 25, 22, 18, 13,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 14.8 },
         { sp: 120, csm: 20.6 },
      ],
      capacity: 81,
      type: "EV",
   },
   {
      model: "Tesla Model Y Performance June 2022",
      brand: "Tesla",
      chargingCurve: [
         229, 218, 212, 207, 203, 197, 198, 192, 190, 185, 183, 180, 178, 177, 175, 171, 168, 166, 163, 160, 157, 153,
         148, 145, 142, 139, 136, 133, 130, 128, 125, 123, 120, 117, 115, 113, 111, 109, 106, 104, 101, 99, 97, 95, 92,
         90, 89, 86, 85, 83, 81, 80, 79, 78, 77, 76, 75, 74, 73, 72, 71, 70, 68, 66, 65, 62, 58, 55, 51, 49, 47, 45, 44,
         42, 41, 40, 38, 37, 35, 33, 32,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 14.6 },
         { sp: 120, csm: 20.5 },
      ],
      capacity: 74.4,
      type: "EV",
   },
   {
      model: "Tesla Model Y RWD 125 kW charger",
      brand: "Tesla",
      chargingCurve: [
         123, 123, 123, 123, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122,
         122, 122, 122, 122, 121, 120, 118, 117, 117, 117, 115, 113, 112, 110, 109, 106, 104, 102, 100, 99, 97, 95, 92,
         90, 88, 85, 83, 81, 79, 78, 76, 74, 73, 72, 70, 69, 68, 66, 65, 63, 62, 61, 60, 59, 57, 55, 54, 52, 51, 50, 48,
         47, 46, 45, 44, 42, 41, 39, 38, 37, 35, 34, 32, 30, 27, 24, 21, 14, 7,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 14.8 },
         { sp: 120, csm: 20.6 },
      ],
      capacity: 60,
      type: "EV",
   },
   {
      model: "Tesla Model Y RWD 200 A charger",
      brand: "Tesla",
      chargingCurve: [
         71, 71, 71, 71, 71, 70, 69, 67, 68, 68, 69, 70, 71, 71, 71, 72, 72, 71, 71, 71, 72, 71, 72, 71, 72, 72, 72, 72,
         73, 72, 73, 72, 73, 72, 73, 73, 73, 74, 73, 74, 74, 73, 73, 74, 73, 74, 74, 74, 74, 74, 74, 74, 74, 74, 74, 74,
         74, 73, 71, 69, 68, 67, 65, 64, 62, 60, 59, 58, 56, 54, 53, 51, 50, 48, 46, 46, 44, 42, 41, 40, 38, 37, 35, 33,
         32, 30, 27, 24, 20, 14, 7,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 14.8 },
         { sp: 120, csm: 20.6 },
      ],
      capacity: 60,
      type: "EV",
   },
   {
      model: "VW ID3 62 kWh 120 kW update",
      brand: "Volkswagen",
      chargingCurve: [
         128, 128, 128, 130, 130, 130, 131, 132, 133, 130, 126, 122, 118, 115, 111, 109, 107, 106, 103, 101, 100, 98,
         97, 96, 95, 93, 91, 90, 88, 87, 85, 83, 82, 81, 80, 79, 78, 77, 75, 74, 73, 72, 71, 70, 69, 68, 67, 66, 65, 63,
         62, 61, 58, 57, 56, 55, 54, 53, 52, 50, 50, 49, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 48, 46, 44, 40, 37, 33,
         32, 31, 30, 29, 28, 26, 25, 25, 24, 23, 22, 21, 3,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 13.5 },
         { sp: 120, csm: 20.5 },
      ],
      capacity: 55.8,
      type: "EV",
   },
   {
      model: "VW ID Buzz 82 kWh October 2022",
      brand: "Volkswagen",
      chargingCurve: [
         183, 183, 184, 184, 184, 185, 185, 186, 185, 184, 183, 181, 180, 177, 175, 173, 170, 165, 160, 156, 151, 147,
         144, 141, 138, 136, 133, 132, 128, 127, 125, 123, 120, 118, 117, 115, 112, 111, 108, 107, 106, 105, 103, 102,
         101, 100, 99, 98, 97, 96, 95, 94, 93, 92, 90, 89, 89, 89, 89, 88, 87, 87, 87, 87, 87, 87, 87, 87, 87, 87, 87,
         87, 85, 82, 80, 77, 73, 70, 67, 64, 60, 57, 54, 52, 47, 44, 42, 36, 34, 29, 26,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 20.6 },
         { sp: 120, csm: 30.2 },
      ],
      capacity: 74.5,
      type: "EV",
   },
   {
      model: "VW ID4 82 kWh March 2021",
      brand: "Volkswagen",
      chargingCurve: [
         125, 125, 125, 125, 125, 125, 125, 125, 125, 125, 125, 125, 125, 125, 125, 125, 125, 125, 125, 125, 125, 125,
         125, 124, 123, 122, 121, 120, 119, 116, 114, 112, 110, 109, 106, 105, 103, 101, 100, 98, 97, 95, 94, 92, 91,
         89, 88, 86, 85, 83, 82, 80, 79, 76, 74, 73, 71, 69, 68, 67, 64, 63, 63, 63, 64, 63, 63, 64, 64, 64, 64, 64, 64,
         61, 58, 54, 49, 45, 41, 39, 38, 36, 35, 34, 32, 31, 30, 30, 30, 30, 30,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 16.7 },
         { sp: 120, csm: 25.1 },
      ],
      capacity: 75,
      type: "EV",
   },
   {
      model: "VW ID5 GTX 82 kWh June 2022",
      brand: "Volkswagen",
      chargingCurve: [
         172, 172, 172, 173, 173, 174, 174, 174, 175, 175, 175, 173, 170, 162, 158, 154, 149, 146, 142, 139, 136, 133,
         130, 128, 127, 124, 122, 120, 118, 116, 114, 112, 110, 107, 106, 105, 101, 98, 96, 95, 92, 91, 90, 89, 89, 90,
         88, 86, 85, 83, 82, 80, 77, 76, 74, 72, 70, 69, 68, 66, 64, 63, 64, 63, 63, 63, 64, 64, 64, 63, 63, 63, 64, 61,
         58, 54, 49, 44, 41, 40, 38,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 15.4 },
         { sp: 120, csm: 22.0 },
      ],
      capacity: 73.4,
      type: "EV",
   },
   {
      model: "Volvo C40 78 kWh March 2022",
      brand: "Volvo",
      chargingCurve: [
         146, 147, 148, 147, 148, 148, 148, 149, 149, 150, 145, 147, 146, 144, 145, 138, 141, 140, 141, 132, 135, 136,
         136, 133, 133, 127, 128, 118, 121, 122, 116, 118, 113, 110, 109, 101, 104, 105, 102, 97, 100, 102, 97, 99, 97,
         94, 91, 87, 85, 82, 83, 80, 78, 77, 70, 71, 72, 71, 71, 65, 67, 63, 63, 60, 61, 52, 54, 49, 47, 42, 38, 37, 35,
         34, 30, 31, 31, 30, 14, 14, 14,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 19.9 },
         { sp: 120, csm: 28.3 },
      ],
      capacity: 75.8,
      type: "EV",
   },
   {
      model: "Volvo XC40 69 kWh April 2022",
      brand: "Volvo",
      chargingCurve: [
         131, 131, 131, 132, 133, 133, 133, 133, 133, 125, 126, 128, 130, 116, 120, 122, 124, 118, 121, 123, 117, 120,
         121, 117, 119, 120, 115, 116, 117, 118, 112, 114, 116, 111, 113, 114, 109, 111, 111, 107, 109, 104, 107, 108,
         106, 105, 100, 108, 95, 97, 98, 93, 94, 88, 90, 87, 83, 85, 86, 81, 82, 76, 77, 70, 72, 72, 69, 66, 63, 64, 58,
         57, 56, 54, 50, 51, 44, 44, 44, 41, 31,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 17.5 },
         { sp: 120, csm: 26.5 },
      ],
      capacity: 63,
      type: "EV",
   },
   {
      model: "Volvo XC40 69 kWh April 2022 cold",
      brand: "Volvo",
      chargingCurve: [
         77, 77, 79, 75, 77, 72, 75, 76, 78, 78, 78, 81, 85, 83, 84, 87, 86, 92, 95, 100, 101, 104, 108, 109, 111, 111,
         107, 109, 111, 112, 107, 109, 110, 107, 108, 108, 106, 104, 105, 102, 104, 101, 102, 101, 100, 97, 98, 96, 95,
         96, 92, 93, 89, 90, 88, 85, 85, 83, 82, 83, 78, 79, 75, 76, 73, 73, 70, 69, 64, 64, 64, 58, 57, 54, 54, 47, 47,
         44, 34, 34, 34,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 17.5 },
         { sp: 120, csm: 26.5 },
      ],
      capacity: 63,
      type: "EV",
   },
   {
      model: "Xpeng P7 September 2022",
      brand: "Xpeng",
      chargingCurve: [
         70, 72, 72, 72, 72, 74, 74, 74, 74, 74, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 77, 77, 77, 77, 77, 77,
         77, 77, 71, 79, 79, 79, 79, 79, 79, 79, 74, 74, 74, 74, 74, 74, 75, 75, 75, 69, 69, 69, 69, 69, 69, 69, 69, 59,
         59, 59, 59, 53, 53, 53, 53, 47, 47, 47, 47, 47, 47, 27, 27, 27, 27, 27, 27, 27, 27, 28, 28, 28, 29,
      ].map((v, i) => ({
         soc: (i + 10) / 100,
         pow: v,
      })),
      consumptionCurve: [
         { sp: 90, csm: 15.0 },
         { sp: 120, csm: 21.2 },
      ],
      capacity: 73.8,
      type: "EV",
   },
]
