import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material"
import { Chart, ChartData, ChartOptions } from "chart.js"
import "chart.js/auto"
import dragDataPlugin from "chartjs-plugin-dragdata"
import { isEqual, sortBy } from "lodash"
import { FunctionComponent, useEffect, useRef, useState } from "react"
import { Line } from "react-chartjs-2"
import { CscEntry } from "../../../out/gen/openapi/routeplanner"
import { t } from "i18next"

Chart.register(dragDataPlugin)

interface IConsumptionCurveComponentProps {
   currentConsumptionCurve: CscEntry[]
   updateConsumptionCurve: (curve: CscEntry[]) => void
}

let resetChart = false

function fillCurve(curve: CscEntry[]): CscEntry[] {
   const targetSpeeds = [0, 30, 60, 90, 120]
   const targetConsumption = [0, -1, -1, -1, -1]
   let res: CscEntry[] = [...curve]

   for (let i = 0; i < targetSpeeds.length; i++) {
      res = sortBy(res, e => e.sp)

      const speed = targetSpeeds[i]
      const entry = curve.find(e => e.sp === speed)
      if (entry === undefined) {
         const fillEntry: CscEntry = {
            sp: speed,
            csm: targetConsumption[i],
         }
         if (fillEntry.csm >= 0) {
            res.push(fillEntry)
         } else {
            const leftEntry: CscEntry = sortBy(res, e => {
               const diff = e.sp - speed
               if (diff < 0) {
                  return diff * -1
               } else {
                  return Number.MAX_SAFE_INTEGER
               }
            })[0]
            const rightEntry = curve.find(e => e.sp > speed)
            const leftSpeed = leftEntry?.sp ?? 0
            const leftCsm = leftEntry?.csm ?? 0

            const rightSpeed = rightEntry?.sp ?? 0
            const rightCsm = rightEntry?.csm ?? 0

            if (rightSpeed > 0 && rightCsm > 0) {
               const nrs = rightSpeed - leftSpeed // 37,5-7,5 =30
               const nrc = rightCsm - leftCsm // 12-26=-14

               const nos = speed - leftSpeed //30-7,5=22,5
               const noc = (nos / nrs) * nrc //22,5/30 * -14 =-10,5

               res.push({
                  sp: speed,
                  csm: noc + leftCsm, // -10,5 + 26 = 15,5
               })
            }
         }
      }
   }
   // res.push(...curve)

   return sortBy(res, e => e.sp)
}

export const ConsumptionCurveComponent: FunctionComponent<IConsumptionCurveComponentProps> = props => {
   const [open, setOpen] = useState(false)
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
   const fullScreenHeight = useMediaQuery("(max-height: 700px)")
   const chart = useRef<Chart<"line", number[], string>>(null)
   const [chartData, setChartData] = useState<ChartData<"line", number[], string>>({
      datasets: [],
   })

   const init = () => {
      const filledCsc = fillCurve(props.currentConsumptionCurve)
      const speeds = filledCsc.map(c => c.sp)
      const consumption = filledCsc.map(c => c.csm)
      return {
         labels: speeds.map(s => `${s}km/h`),
         datasets: [
            {
               data: consumption,
               label: "kWh / 100km",
               // fill: true,
               tension: 0,
               borderWidth: 4,
               borderColor: theme.palette.secondary.main,
               // backgroundColor: theme.palette.secondary.dark,
               pointHitRadius: 25,
            },
         ],
      }
   }

   useEffect(() => {
      setChartData(init())
   }, [props.currentConsumptionCurve])

   const handleClickOpen = () => {
      setOpen(true)
      if (resetChart) {
         setChartData(init())
         resetChart = false
      }
   }

   const handleClose = (isOk: boolean) => {
      setOpen(false)
      const newCurve: CscEntry[] = chartData.datasets[0].data.map((kw, index) => {
         return {
            csm: kw,
            sp: fillCurve(props.currentConsumptionCurve)[index].sp,
         }
      })
      if (isOk && !isEqual(props.currentConsumptionCurve, newCurve)) {
         props.updateConsumptionCurve(newCurve)
      } else {
         resetChart = true
      }
   }

   // const formatOptions: Intl.NumberFormatOptions = {
   //    maximumFractionDigits: 0,
   //    minimumFractionDigits: 0,
   //    style: "percent",
   // }

   // const formatter = new Intl.NumberFormat(undefined, formatOptions)

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   const plugins: any = {
      dragData: {
         round: 1,
         showTooltip: true,
         // onDragStart: function (e: any, datasetIndex: any, index: any, value: any) {
         //    // console.log(e, datasetIndex, index, value)
         // },
         // onDrag: (e: MouseEvent, datasetIndex: number, index: number, value: number) => {
         //    // e.target.style.cursor = "grabbing"
         //    // console.log(e, datasetIndex, index, value)
         // },
         // onDragEnd: (e: MouseEvent, datasetIndex: number, index: number, value: number) => {},
      },
   }

   const options: ChartOptions<"line"> = {
      animation: false,
      maintainAspectRatio: false,
      resizeDelay: 32,
      scales: {
         y: {
            min: 0,
            max: 50,
         },
      },
      onHover: e => {
         if (e.native !== null && e.native.target !== null) {
            const point = chart.current?.getElementsAtEventForMode(e.native, "nearest", { intersect: true }, false)
            if (point !== undefined && point.length > 0) {
               ;(e.native.target as HTMLElement).style.cursor = "grab"
            } else {
               ;(e.native.target as HTMLElement).style.cursor = "default"
            }
         }
      },
      plugins: plugins,
   }
   return (
      <Box sx={{ pt: 1 }}>
         <Button variant="outlined" onClick={handleClickOpen} disabled={props.currentConsumptionCurve.length === 0}>
            {t("ConsumptionCurve_Edit_Consumption")}
         </Button>
         <Dialog
            fullScreen={fullScreen || fullScreenHeight}
            fullWidth={true}
            maxWidth={"xl"}
            open={open}
            PaperProps={{
               sx: {
                  minHeight: "80vh",
               },
            }}
            onClose={() => handleClose(false)}
            aria-labelledby={t("ConsumptionCurve_Edit_Consumption")}
         >
            <DialogTitle id="responsive-dialog-title">{t("ConsumptionCurve_Edit_Consumption")}</DialogTitle>
            <DialogContent sx={{ height: "100%", display: "flex" }}>
               <Box sx={{ flexGrow: "1", minWidth: 0 }}>
                  <Line ref={chart} options={options} data={chartData} plugins={[{ id: "dragData" }]} />
               </Box>
            </DialogContent>
            <DialogActions>
               <Button onClick={() => handleClose(false)} autoFocus>
                  {t("Generic_Cancel")}
               </Button>
               <Button onClick={() => handleClose(true)} autoFocus>
                  {t("Generic_Ok")}
               </Button>
            </DialogActions>
         </Dialog>
      </Box>
   )
}
